<template>
  <li v-bind:style="{ order: id }" class="facet" :class="{ opened: isOpened }">
    <div
      class="facet-header facet-row"
      :class="{ opened: isOpened, error: hasError }"
      @click="toggleFacet()"
    >
      <div class="icon facet-icon icon-car"></div>
      <h4 class="truncated opened">Maximale reistijd</h4>
      <h4 class="truncated closed">Postcode {{ origin }}</h4>
      <div class="value">{{ sanitizedTime }}</div>
    </div>
    <div
      class="facet-body toggle-body"
      :class="{ opened: isOpened }"
      :style="{ maxHeight: toggleHeight + 'px' }"
    >
      <input-range
        :min="10"
        :max="120"
        :step="10"
        v-model:value="travelTime"
        @change:value="updateTravelTime(travelTime)"
      ></input-range>
      <label for="origin">Naar postcodegebied</label>
      <input
        :class="{ error: hasError }"
        name="origin"
        type="text"
        v-model="origin"
        @input="updateOrigin(origin)"
      />
      <div class="form-feedback">{{ hasError }}</div>
      <div class="button-bar">
        <button class="remove" @click="$emit('remove')">
          Verwijderen
          <!-- <div class="icon icon-delete"></div> -->
        </button>
        <button class="secundair" @click="closeFacet()">
          Sluiten
        </button>
      </div>
    </div>
  </li>
</template>

<script>
/*
TODO
Om slider meer responsive te maken:
@update:value="updateTravelTime(travelTime)"
vervangen door een @change ofzo..
*/
import inputRange from "./InputRange.vue";

export default {
  name: "CarTravelTimeCard",
  components: { inputRange },
  props: ["carTravelTime","status"],
  data() {
    return {
      origin: String,
      travelTime: Number,
      id: Number,
      error: Boolean,
      // toggle values
      isOpened: Boolean,
      toggleHeight: 0
    };
  },
  computed: {
    sanitizedTime() {
      const hours = Math.floor(this.travelTime / 60);
      const minutes = this.travelTime % 60;
      return hours + ":" + zfill(minutes, 2);

      function zfill(number, size) {
        number = number.toString();
        while (number.length < size) number = "0" + number;
        return number;
      }
    },
    hasError() {
      var error = "";
      // 2. postcode (file) not found = only relevant if postcode has valid format
      if (this.error) {
        error = "Die postcode heb ik niet";
      }
      // 1. not correct postcode input, trumps file-not-found
      const value = this.origin;
      var itIsNumber = this.checkFormat(value);
      if (!itIsNumber) {
        error = "4 cijfers van postcode";
      }

      return error;
    }
  },
  watch: {status(value) {
      value.filter(e => e.id == this.id).forEach(e => {
        this.error = e.error
      });
  }},
  methods: {
    checkFormat(value) {
      // returns true if value is 4 digits exactly
      return /^\d{4}$/.test(value);
    },
    updateOrigin(item) {
      if (this.checkFormat(item)) {
        this.origin = item;
        this.$emit("update:cartraveltime", {
          id: this.id,
          origin: item,
          travelTime: this.travelTime,
          error: this.error
        });
      }
    },
    updateTravelTime(item) {
      this.travelTime = Number(item);
      this.$emit("update:cartraveltime", {
        id: this.id,
        origin: this.origin,
        travelTime: Number(item),
          error: this.error
      });
    },
    // toggle methods for fixed height items
    toggleFacet() {
      if (this.isOpened == true) {
        this.closeFacet();
      } else {
        this.openFacet();
      }
    },
    openFacet() {
      this.toggleHeight =
        this.$el.querySelector(".toggle-body").scrollHeight + 28; // add 28 px for displaying input feedback
      this.isOpened = true;
    },
    closeFacet() {
      this.toggleHeight = 0;
      this.isOpened = false;
    }
  },
  emits: ["update:cartraveltime", "remove", "mounted"],
  mounted() {
    console.log(
      "[CarTravelTimeCard] mounted",
      JSON.stringify(this.carTravelTime)
    );
    this.origin = this.carTravelTime.origin;
    this.travelTime = this.carTravelTime.travelTime;
    this.id = this.carTravelTime.id;
    this.error = this.carTravelTime.error;
    // toggle: open the facet if it is suppose to be open on startup
    this.isOpened = this.carTravelTime.opened;
    if (this.isOpened === true) {
      this.openFacet();
    }
    this.$emit("mounted");
  }
};
</script>
