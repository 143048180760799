<template>
  <div ref="map-root" class="map-panel"></div>
  <div class="no-area" v-if="noArea">Geen doelgebied gevonden</div>
</template>
<script>
import View from "ol/View";
import OpenLayerMap from "ol/Map";
import TileLayer from "ol/layer/Tile";
import { fromLonLat } from "ol/proj";
import XYZ from "ol/source/XYZ";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { Fill, Style } from "ol/style";
import { Attribution, defaults as defaultControls } from "ol/control";
import "ol/ol.css";
import { interSectMultipleFC } from "../js/InterSect";
import {
  getPostcodeTravelTimeFC,
  getStationsFC,
} from "../js/RetrieveS3GeoJson";
export default {
  name: "MapContainer",
  components: {},
  props: ["carTravelTimes", "stationProximities"],
  data: () => ({
    olMap: null,
    vectorLayer: null,
    featureMap: new Map(),
    noArea: true,
  }),
  emits: ["update:cartraveltimestatus"],
  mounted() {
    console.log(
      "[MapContainer] mounted carTravelTimes",
      JSON.stringify(this.carTravelTimes),
      "stationProximities",
      JSON.stringify(this.stationProximities)
    );
    this.pcLayer = new VectorLayer({
      source: new VectorSource({
        features: [],
      }),
      style: new Style({
        fill: new Fill({
          //color: "rgba(77,168,150,0.35)",
          color: "rgba(77,168,150,0.1)",
        }),
      }),
    });
    this.stationLayer = new VectorLayer({
      source: new VectorSource({
        features: [],
      }),
      style: new Style({
        fill: new Fill({
          // color: "rgba(83,189,175,0.35)",
          color: "rgba(83,189,175,0.1)",
        }),
      }),
    });
    this.intersectLayer = new VectorLayer({
      source: new VectorSource({
        features: [],
      }),
      style: new Style({
        fill: new Fill({
          //color: "rgba(174,174,165,0.7)"
          color: "rgba(77,168,150,0.4)",
        }),
      }),
    });
    var view = new View({
      center: fromLonLat([5.1214, 52.0907]),
      zoom: 10,
      enableRotation: false,
      constrainResolution: true,
      extent: [
        -148890.13130335102,
        6418988.54504038,
        1266112.136311832,
        7243285.458067721,
      ], // only NL visible
    });

    var attribution = new Attribution({
      collapsible: false,
    });
    var attributions =
      '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>';
    this.olMap = new OpenLayerMap({
      target: this.$refs["map-root"],
      layers: [
        // layer 0: Map
        new TileLayer({
          source: new XYZ({
            attributions: attributions,
            url: "https://tile.osmand.net/hd/{z}/{x}/{y}.png",
            tilePixelRatio: 2,
          }),
        }),
        this.intersectLayer,
      ],
      view,
      controls: defaultControls({ attribution: false }).extend([attribution]),
    });
    this.carTravelTimes.forEach((c) => this.updateCarTravelTime(c));
    this.stationProximities.forEach((s) => this.updateStationProximity(s));
    this.updateIntersectLayer();
  },
  watch: {
    // todo move this logic to a separate js file
    carTravelTimes(value) {
      Array.from(this.featureMap.keys()).forEach((k) =>
        console.log("[MapContainer] watch carTravelTimes key", k)
      );
      // remove entries from featureMap
      Array.from(this.featureMap.keys())
        .filter((key) => !value.map((c) => "car" + c.id).includes(key))
        .filter((key) => key.startsWith("car"))
        .forEach((k) => {
          console.log("[MapContainer] car delete", k);
          this.featureMap.delete(k);
          this.updateIntersectLayer();
        });
      // determine which one changed
      value
        .filter((c) => c.changed)
        .forEach((c) => {
          console.log("[MapContainer] changed car", c.id);
          this.updateCarTravelTime(c);
        });
    },
    stationProximities(value) {
      Array.from(this.featureMap.keys()).forEach((k) =>
        console.log("[MapContainer] watch stationProximities key", k)
      );
      // remove entries from featureMap
      Array.from(this.featureMap.keys())
        .filter((key) => !value.map((c) => "station" + c.id).includes(key))
        .filter((key) => key.startsWith("station"))
        .forEach((k) => {
          console.log("[MapContainer] station delete", k);
          this.featureMap.delete(k);
          this.updateIntersectLayer();
        });
      // determine which one changed
      value
        .filter((c) => c.changed)
        .forEach((c) => {
          console.log("[MapContainer] changed station", c.id);
          this.updateStationProximity(c);
        });
    },
  },
  methods: {
    updateIntersectLayer() {
      Array.from(this.featureMap.keys()).forEach((k) =>
        console.log("[MapContainer] updateIntersectLayer key", k)
      );
      let allArrays = Array.from(this.featureMap.values());
      var { intersectionFound, result, extent } = interSectMultipleFC(allArrays);
      this.noArea = !intersectionFound
      var source = this.intersectLayer.getSource();
      source.clear();
      const features = new GeoJSON({
        featureProjection: "EPSG:3857",
      }).readFeatures(result);
      source.addFeatures(features);
      this.olMap.getView().fit(extent, this.olMap.getSize());
    },

    updateCarTravelTime(c) {
      console.log("[MapContainer] updateCarTravelTime", c);
      getPostcodeTravelTimeFC(
        c.origin,
        c.travelTime,
        (geojson) => {
          this.featureMap.set("car" + c.id, geojson);
          this.updateIntersectLayer();
          this.$emit("update:cartraveltimestatus", { id: c.id, error: false });
        },
        (error) => {
          this.featureMap.delete("car" + c.id);
          this.updateIntersectLayer();
          console.log("[MapContainer] failed to retrieve", error);
          this.$emit("update:cartraveltimestatus", { id: c.id, error: true });
        }
      );
    },
    updateStationProximity(c) {
      getStationsFC(c.distance, (geojson) => {
        this.featureMap.set("station" + c.id, geojson);
        this.updateIntersectLayer();
      });
    },
  },
};
</script>
<style>
/* used variables 
--color-button-background-hover
--color-button-text-hover
--color-attribution-background
--border-radius
--space-m -> space-m
--button-size
--space-xs
--color-button-text
--font-size
--font-size-mini
--line-height
*/
.ol-control {
  position: absolute;
  background-color: transparent;
  border-radius: unset;
  padding: unset;
}
.ol-control:hover {
  background-color: var(--color-button-background-hover);
  background-color: transparent;
}
.ol-zoom {
  top: var(--space-m);
  left: unset;
  right: var(--space-m);
}
.ol-rotate {
  top: calc(
    var(--space-m) * 3 + var(--button-size) * 2 + var(--space-xs) * 2.5
  );
  right: var(--space-m);
  transition: opacity 0.25s linear, visibility 0s linear;
}
.ol-rotate.ol-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s linear, visibility 0s linear 0.25s;
}
.ol-control button {
  margin: unset;
  color: var(--color-button-text);
  font-size: var(--font-size);
  font-weight: unset;
  height: var(--button-size);
  width: var(--button-size);
  line-height: unset;
  background-color: var(--color-button-background);
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
}
.ol-control button::-moz-focus-inner {
  border: none;
  padding: 0;
}
.ol-zoom-extent button {
  line-height: var(--line-height);
}
.ol-compass {
  display: block;
  font-weight: normal;
  font-size: var(--font-size);
  will-change: transform;
}
.ol-touch .ol-control button {
  font-size: var(--font-size);
}
.ol-touch .ol-zoom-extent {
  top: var(--space-m);
}
/* Hide zoom controlls on touch-devices */
.ol-touch .ol-zoom-in,
.ol-touch .ol-zoom-out {
  display: none;
}
.ol-control button:hover,
.ol-control button:focus {
  text-decoration: none;
  background-color: var(--color-button-background-hover);
  color: var(--color-button-text-hover);
  border-radius: var(--border-radius);
}
.ol-zoom .ol-zoom-in {
  border-radius: var(--border-radius);
}
.ol-zoom .ol-zoom-out {
  border-radius: var(--border-radius);
  margin-top: var(--space-xs);
}
/*
  .ol-attribution {
    display: none;
  }
  */
.ol-attribution {
  text-align: right;
  bottom: var(--space-m);
  right: var(--space-m);
  max-width: calc(100% - var(--space-m) * 2);
  overflow: hidden;
  border-radius: unset;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
}
.ol-attribution ul {
  margin: 1px 4px 4px 0;
  padding: unset;
  padding-left: var(--space-m);
  padding-right: var(--space-xs);
  font-size: var(--font-size-mini);
  color: unset;
  text-shadow: unset;
}
.ol-attribution li a {
  line-height: 0.5;
}
.ol-attribution.ol-uncollapsible {
  bottom: 0;
  right: 0;
  border-radius: unset;
  border-top-left-radius: var(--border-radius);
}

/* blurred backgrounds */
.ol-attribution:not(.ol-collapsed) {
  /* background: var(--color-attribution-background); */
  -webkit-backgrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.2);
}
@-moz-document url-prefix() {
  .ol-attribution:not(.ol-collapsed) {
    background-color: var(--color-attribution-background);
  }
}
</style>
