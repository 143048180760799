<template>
  <div class="facet-wrapper" :class="{ opened: isOpened }">
    <div class="facet facet-header facet-row blurred" @click="toggleFacets()">
      <div
        class="icon facet-icon icon-target-area"
        :class="{ opened: isOpened }"
      ></div>
      <h1>Droomgebied</h1>
      <div
        v-if="isOpened == false"
        class="icon facet-icon facet-icon-right icon-expand"
      ></div>
      <div
        v-if="isOpened == true"
        class="icon facet-icon facet-icon-right icon-collapse"
      ></div>
    </div>
    <div class="all-facets" :class="{ opened: isOpened }">
      <ul class="facet-list">
        <CarTravelTimeCard
          v-for="item in carTravelTimes"
          :key="item.id"
          :carTravelTime="item"
          :status="cardStatus"
          @update:cartraveltime="updateCarTravelTime"
          @remove="removeCarTravelTime(item)"
          @mounted="updateCarTravelTime(item)"
        />
        <StationProximityCard
          v-for="item in stationProximities"
          :key="item.id"
          :stationProximity="item"
          @update:stationproximity="updateStationProximity"
          @remove="removeStationProximity(item)"
          @mounted="updateStationProximity(item)"
        />
      </ul>
      <AddCard
        :cartravelSpace="carTravelTimesMax - carTravelTimes.length"
        :stationSpace="stationProximitiesMax - stationProximities.length"
        @add:station="$emit('add:stationproximity')"
        @add:cartraveltime="$emit('add:cartraveltime')"
      />
    </div>
  </div>
</template>

<script>
import CarTravelTimeCard from "./CarTravelTimeCard.vue";
import StationProximityCard from "./StationProximityCard.vue";
import AddCard from "./AddCard.vue";

export default {
  name: "FacetContainer",
  components: { CarTravelTimeCard, StationProximityCard, AddCard },
  props: {
    carTravelTimes: Object,
    carTravelTimesMax: Number,
    stationProximities: Object,
    stationProximitiesMax: Number,
    cardStatus: Object
  },
  data() {
    return {
      isOpened: true,
    };
  },
  methods: {
    updateCarTravelTime(value) {
      // emit array with updated value
      var newArray = this.carTravelTimes.map((e) => {
        if (e.id == value.id) {
          value.changed = true;
          return value;
        } else {
          e.changed = false;
          return e;
        }
      });
      this.$emit("update:cartraveltimes", newArray);
    },
    removeCarTravelTime(item) {
      var newArray = this.carTravelTimes.filter((s) => s.id != item.id);
      this.$emit("update:cartraveltimes", newArray);
    },
    updateStationProximity(value) {
      // emit array with updated value
      var newArray = this.stationProximities.map((e) => {
        if (e.id == value.id) {
          value.changed = true;
          return value;
        } else {
          e.changed = false;
          return e;
        }
      });
      this.$emit("update:stationproximities", newArray);
    },
    removeStationProximity(item) {
      var newArray = this.stationProximities.filter((s) => s.id != item.id);
      this.$emit("update:stationproximities", newArray);
    },
    toggleFacets() {
      // opens and closes the visibility of facets
      this.isOpened = !this.isOpened;
    },
  },
  emits: [
    "update:cartraveltimes",
    "update:stationproximities",
    "add:cartraveltime",
    "add:stationproximity",
  ],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
