const AWS_BUCKET_URL = "https://droomgebied.s3.amazonaws.com/";

export function getPostcodeTravelTimeFC(postcode, travelTime, callback, error) {
  var url = AWS_BUCKET_URL + "traveltime/traveltime_postcode_" + postcode + ".json";
  console.log("[getPostcodeTravelTimeFC]", url);
  fetch(url)
    .then((response) => response.json())
    .then((data) => {
      const filteredFeatures = data.features.filter(
        (f) => f.properties.traveltime == travelTime.toString()
      );
      callback(makeFeatureCollectionOfListOfFeatures(filteredFeatures));
    })
    .catch((e) => {
      console.log("getPostcodeTravelTimeFC] failed", e);
      error("failed to retrieve postcode "+postcode+ " traveltime "+ travelTime);
    });
}

export function getStationsFC(radius, callback) {
  var url = AWS_BUCKET_URL + "nsstations/nsstations" + radius + ".json";
  console.log("[getStationsFC]", url);
  fetch(url)
    .then((response) => response.json())
    .then((data) => callback(makeFeatureCollectionOfListOfFeatures([data])));
}

function makeFeatureCollectionOfListOfFeatures(listOfFeatures) {
  return {
    type: "FeatureCollection",
    crs: { type: "name", properties: { name: "urn:ogc:def:crs:EPSG::3857" } },
    features: listOfFeatures
  };
}
