<template>
  <div class="expand-button blurred">
    <div
      class="toggle-body body"
      :class="{ opened: isOpened }"
      :style="{ maxHeight: toggleHeight + 'px' }"
    >
      <div class="spacer"></div>
      <h2>Data bronnen</h2>
      <ul>
        <li>PDOK loket</li>
        <li>CBS</li>
        <li>OBJECT VISION BV</li>
        <li>OsmAnd BV</li>
        <li>
          <a href="https://www.openstreetmap.org/copyright" target="_blank"
            >OpenStreetMap contributors</a
          >
        </li>
      </ul>
      <div class="spacer"></div>
    </div>
    <div
      class="info-button icon icon-button icon-sources"
      :class="{ opened: isOpened }"
      @click="toggleFacet()"
    ></div>
  </div>
</template>

<script>
export default {
  name: "SourceInformation",
  components: {},
  props: [],
  data() {
    return {
      // toggle values
      isOpened: false,
      toggleHeight: 0,
    };
  },
  methods: {
    // toggle methods for fixed height items
    toggleFacet() {
      if (this.isOpened == true) {
        this.closeFacet();
      } else {
        this.openFacet();
      }
    },
    openFacet() {
      this.toggleHeight = this.$el.querySelector(".toggle-body").scrollHeight;
      this.isOpened = true;
    },
    closeFacet() {
      this.toggleHeight = 0;
      this.isOpened = false;
    },
  },
  emits: [],
};
</script>
<style scoped></style>
