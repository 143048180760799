<template>
  <div class="add-buttons">
    <section class="step-1" v-if="activeStep == 1">
      <button class="add-card facet facet-row" @click="nextStep()">
        <div class="icon facet-icon icon-plus"></div>
        Filter toevoegen
      </button>
    </section>
    <section class="step-2" v-if="activeStep == 2">
      <button class="add-card facet facet-row" @click="stepDown()">
        <div class="icon facet-icon icon-minus"></div>
        Annuleren
      </button>
      <button
        class="add-card facet blurred facet-row"
        v-if="cartravelSpace > 0"
        @click="stepDown(), $emit('add:cartraveltime')"
      >
        <div class="icon facet-icon icon-car"></div>
        Maximale reistijd
      </button>
      <button
        class="add-card facet blurred facet-row"
        v-if="stationSpace > 0"
        @click="stepDown(), $emit('add:station')"
      >
        <div class="icon facet-icon icon-train"></div>
        Nabijheid van treinstation
      </button>
      <button class="add-card facet blurred facet-row" v-if="totalSpace == 0">
        <div class="icon facet-icon icon-account-plus"></div>
        Eindeloos filters met Pro
      </button>
    </section>
  </div>
  <div ref="bottom"></div>
</template>

<script>
export default {
  name: "AddCard",
  props: {
    cartravelSpace: Number,
    stationSpace: Number,
  },
  data() {
    return {
      activeStep: 1,
    };
  },
  computed: {
    totalSpace() {
      return this.cartravelSpace + this.stationSpace;
    },
  },
  methods: {
    nextStep() {
      this.activeStep++;
    },
    stepDown() {
      this.activeStep--;
    },
  },
  emits: ["add:station", "add:cartraveltime"],
};
</script>
