<template>
  <li v-bind:style="{ order: id }" class="facet" :class="{ opened: isOpened }">
    <div
      class="facet-header facet-row"
      :class="{ opened: isOpened }"
      @click="toggleFacet()"
    >
      <div class="icon facet-icon icon-train"></div>
      <h4 class="truncated opened">Treinstation binnen</h4>
      <h4 class="truncated closed">Treinstation binnen</h4>
      <div class="value">{{ distance }} km</div>
    </div>
    <div
      class="facet-body toggle-body"
      :class="{ opened: isOpened }"
      :style="{ maxHeight: toggleHeight + 'px' }"
    >
      <input-range
        :min="1"
        :max="15"
        :step="1"
        v-model:value="distance"
        @change:value="updateDistance(distance)"
      ></input-range>
      <div class="button-bar">
        <button class="remove" @click="$emit('remove')">
          Verwijder
        </button>
        <button class="secundair" @click="closeFacet()">
          Sluiten
        </button>
      </div>
    </div>
  </li>
</template>

<script>
import inputRange from "./InputRange.vue";

export default {
  name: "StationProximityCard",
  components: { inputRange },
  props: ["stationProximity"],
  data() {
    return {
      distance: Number,
      id: Number,
      // toggle values
      isOpened: Boolean,
      toggleHeight: 0,
    };
  },
  methods: {
    updateDistance(item) {
      this.distance = Number(item);
      this.$emit("update:stationproximity", {
        id: this.id,
        distance: Number(item),
      });
    },
    // toggle methods for fixed height items
    toggleFacet() {
      if (this.isOpened == true) {
        this.closeFacet();
      } else {
        this.openFacet();
      }
    },
    openFacet() {
      this.toggleHeight = this.$el.querySelector(".toggle-body").scrollHeight;
      this.isOpened = true;
    },
    closeFacet() {
      this.toggleHeight = 0;
      this.isOpened = false;
    },
  },
  emits: ["update:stationproximity", "remove", "mounted"],
  mounted() {
    this.distance = this.stationProximity.distance;
    this.id = this.stationProximity.id;
    // toggle: open the facet if it is suppose to be open on startup
    this.isOpened = this.stationProximity.opened;
    if (this.isOpened === true) {
      this.openFacet();
    }
    this.$emit("mounted");
  },
};
</script>
