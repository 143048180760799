<template>
  <input
    :style="{ '--track-fill': slidePercentage + '%' }"
    type="range"
    :min="min"
    :max="max"
    :step="step"
    :value="value"
    @input="$emit('update:value', $event.target.value)"
    @change="$emit('change:value', $event.target.value)"
  />
</template>

<script>
export default {
  name: "InputRange",
  components: {},
  props: {
    value: Number,
    min: Number,
    max: Number,
    step: Number,
  },
  emits: ["update:value", "change:value"], //the update does nothing but updates the model above, so that the slider and value will react on the card, the change changes the value
  data() {
    return {
      myvalue: 0,
    };
  },
  computed: {
    slidePercentage() {
      const range = this.max - this.min;
      const percentage = ((this.value - this.min) / range) * 100;
      return parseInt(percentage);
    },
  },
  mounted() {
    this.myvalue = this.value;
  },
};
</script>
