<template>
  <MapContainer
    :carTravelTimes="carTravelTimes"
    :stationProximities="stationProximities"
    @update:cartraveltimestatus="updateCardStatus"
  />
  <div class="nav-group">
    <!--<div class="info-button icon icon-button icon-sources"></div>-->
    <SourceInformation />
  </div>
  <FacetContainer
    :carTravelTimes="carTravelTimes"
    :carTravelTimesMax="carTravelTimesMax"
    :cardStatus="cardStatus"
    @update:cartraveltimes="updateCarTravelTimes"
    @add:cartraveltime="addNewCarTravelTime"
    :stationProximities="stationProximities"
    :stationProximitiesMax="stationProximitiesMax"
    @update:stationproximities="updateStationProximities"
    @add:stationproximity="addNewStationProximity"
  />
</template>

<script>
import FacetContainer from "./components/FacetContainer.vue";
import MapContainer from "./components/MapContainer";
import SourceInformation from "./components/SourceInformation";

export default {
  name: "App",
  components: {
    FacetContainer,
    MapContainer,
    SourceInformation
  },
  data() {
    console.log("app created");
    return {
      carTravelTimesMax: 2,
      carTravelNewOrigin: 1722,
      carTravelTimes: [
        // {
        //   id: 1,
        //   origin: 1058,
        //   travelTime: 30,
        //   opened: false,
        //   changed: true,
        //   error: 0,
        // },
        {
          id: 3,
          origin: 2612,
          travelTime: 60,
          opened: false,
          changed: true,
        }
      ],
      stationProximitiesMax: 1,
      stationProximities: [
        // {
        //   id: 2,
        //   distance: 3,
        //   opened: false,
        //   changed: true,
        // },
      ],
      schoolProximitiesMax: 2,
      schoolProximities: [
        {
          id: 4,
          denomination: "openbaar",
          education: "vwo",
          distance: 10
        }
      ],
      nextId: 5,
      cardStatus: [{id: 3, error: false}]
    };
  },
  methods: {
    updateCarTravelTimes(value) {
      console.log("[App] updateCarTravelTimes", JSON.stringify(value));
      this.carTravelTimes = value;
    },
    updateStationProximities(value) {
      console.log("[App] updateStationProximities", JSON.stringify(value));
      this.stationProximities = value;
    },
    addNewCarTravelTime() {
      console.log("addNewCarTravelTime");
      var id = this.nextId++
      this.carTravelTimes.push({
        id,
        origin: this.carTravelNewOrigin,
        travelTime: 30,
        opened: true,
        changed: true,
      });
      this.cardStatus.push({id, error:false})

    },
    addNewStationProximity() {
      this.stationProximities.push({
        id: this.nextId++,
        distance: 5,
        opened: true,
        changed: true
      });
    },
    addNewSchoolProximity() {
      this.schoolProximities.push({
        id: this.nextId++,
        denomination: "katholiek",
        education: "gymnasium",
        distance: 10
      });
    },
    updateCardStatus(value) {
      this.cardStatus = this.cardStatus.map((e) => {
        if (e.id == value.id) {
          e.error = value.error
        } 
        return {id: e.id, error: e.error}
      });
      console.log("[App] updateCarTravelTimeStatus",JSON.stringify(this.cardStatus))
    }
  }
};
</script>

<style>
@import "./assets/css/styles.css";
</style>
