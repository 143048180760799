import turf from "turf";
import { getType } from "@turf/invariant";
import * as jsts from "jsts";
import { intersects } from "ol/extent";
import GeoJSON from "ol/format/GeoJSON";
import {
  Point,
  LineString,
  LinearRing,
  Polygon,
  MultiPoint,
  MultiLineString,
  MultiPolygon
} from "ol/geom.js";

const maskFeature = {
  type: "Feature",
  properties: {},
  geometry: {
    type: "Polygon",
    coordinates: [
      [
        [-3.076171875, 46.49839225859763],
        [16.083984375, 46.49839225859763],
        [16.083984375, 56.897003921272606],
        [-3.076171875, 56.897003921272606],
        [-3.076171875, 46.49839225859763]
      ]
    ]
  }
};

export function interSectMultipleFC(fcs) {
  console.log("[interSectMultipleFC] received features", fcs.length);
  var parser = new jsts.io.OL3Parser();
  parser.inject(
    Point,
    LineString,
    LinearRing,
    Polygon,
    MultiPoint,
    MultiLineString,
    MultiPolygon
  );

  var format = new GeoJSON();
  var geojsonWriter = new jsts.io.GeoJSONWriter();
  if (fcs.length > 0) {
    var intersectionPoly;
    var intersectionExtent;
    fcs.forEach((fc, count) => {
      var features = format.readFeatures(fc, {
        featureProjection: "EPSG:3857"
      });
      var poly = parser.read(features[0].getGeometry());
      var extent = features[0].getGeometry().getExtent();

      if (count == 0) {
        intersectionPoly = poly;
        intersectionExtent = extent;
      } else {
        var intersect = intersects(intersectionExtent, extent);
        console.log("[interSectMultipleFC] intersects", count, intersect);
        if (intersects) {
          intersectionPoly = intersectionPoly.intersection(poly);
        }
      }
    });

    var r = geojsonWriter.write(intersectionPoly);

    var polygons = getPolygons(r);

    if (polygons.filter((p) => checkIfEmpty(p)).length > 0) {
      console.log("[interSectMultipleFC] no calculated intersection");
      return {
        intersectionFound: false,
        result: turf.featureCollection([maskFeature]),
        extent: [
          398000.5445953284,
          6660608.548645073,
          739060.4114028803,
          7021819.575722225
        ]
      };
    }
    var resultvar = [maskFeature.geometry.coordinates[0]];
    removeHoles(polygons).forEach((r) => resultvar.push(r[0]));
    var rr = [resultvar];
    retrieveHoles(polygons).forEach((h) => rr.push(h));
    return {
      intersectionFound: true,
      result: turf.multiPolygon(rr),
      extent: format
        .readFeatures(r, {
          featureProjection: "EPSG:3857"
        })[0]
        .getGeometry()
        .getExtent()
    };
  }

  console.log("[interSectMultipleFC] no input, no intersection");
  return {
    intersectionFound: false,
    result: turf.featureCollection([maskFeature]),
    extent: [
      398000.5445953284,
      6660608.548645073,
      739060.4114028803,
      7021819.575722225
    ]
  };
}

function getPolygons(geojson) {
  var t = getType(geojson);

  if (t == "Polygon") return [geojson.coordinates];
  if (t == "MultiPolygon") {
    return geojson.coordinates;
  }
  if (t == "GeometryCollection") {
    var r = [];
    geojson.geometries
      .filter((g) => getType(g) == "MultiPolygon")
      .forEach((m) => m.coordinates.forEach((n) => r.push(n)));
    geojson.geometries
      .filter((g) => getType(g) == "Polygon")
      .forEach((m) => r.push(m.coordinates));
    return r;
  }
  return [];
}

function removeHoles(coordinatesArray) {
  return coordinatesArray.map((c) => [c[0]]);
}
function retrieveHoles(coordinatesArray) {
  return coordinatesArray.filter((c) => c.length > 1).map((c) => [c[1]]);
}

function checkIfEmpty(array) {
  return (
    Array.isArray(array) && (array.length == 0 || array.every(checkIfEmpty))
  );
}
